import type { TokenMetadata } from '@solana/spl-token-metadata'
import type { PublicKeyInitData, Signer, TransactionInstruction } from '@solana/web3.js'
import type { IDailyRewards, IHoldersClubConfig, IHoldersClubPoolsInfo, IReferral, IUserBalance, IUserData, IUSerEvents, IUserRewards } from '~/services'
import {
  AuthorityType,
  createAssociatedTokenAccountInstruction,
  createBurnInstruction,
  createCloseAccountInstruction,
  createInitializeInstruction,
  createInitializeMetadataPointerInstruction,
  createInitializeMintCloseAuthorityInstruction,
  createInitializeMintInstruction,
  createInitializeNonTransferableMintInstruction,
  createMintToInstruction,
  createSetAuthorityInstruction,
  createUpdateAuthorityInstruction,
  ExtensionType,
  getAssociatedTokenAddressSync,
  getMintLen,
  LENGTH_SIZE,
  TOKEN_2022_PROGRAM_ID, TYPE_SIZE,
} from '@solana/spl-token'
import { pack } from '@solana/spl-token-metadata'
import { useWallet } from '@solana/wallet-adapter-vue'
import { Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram } from '@solana/web3.js'
import bs58 from 'bs58'
import { defineStore } from 'pinia'
import { Notify } from 'quasar'
import jsolIcon from '~/assets/img/icon/jsol.svg?raw'
import solIcon from '~/assets/img/icon/sol.svg?raw'
import usdcIcon from '~/assets/img/icon/usdc.svg?raw'
import { API_HC_URL, HC_CREDENTIAL_NAME, HC_CREDENTIAL_SYMBOL, JPOOL_ISSUER } from '~/config'
import { withPriorityFees } from '~/features/priority-fee'
import { useMonitorTransaction } from '~/hooks'
import { useHoldersClubService } from '~/services'
import { clickWalletButton, dateToUtc, sendTransaction } from '~/utils'

export type CredentialState = {
  loading: boolean
  loadingIssuer: boolean
  userData?: IUserData
  rewards?: IUserRewards
  dailyRewards?: IDailyRewards
  events?: IUSerEvents
  balances?: IUserBalance[]
  referrals?: IReferral[]
}

export const TOKEN_LIST = [
  {
    symbol: 'JSOL',
    mint: '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    icon: jsolIcon,
  },
  {
    symbol: 'SOL',
    mint: 'So11111111111111111111111111111111111111112',
    icon: solIcon,
  },
  {
    symbol: 'USDC',
    mint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    icon: usdcIcon,
  },
]

const boostStartDateDefault = (new Date('2024-08-22T00:00:00.000Z')).getTime()
const boostEndDateDefault = (new Date('2025-09-24T09:00:00.000Z')).getTime()
const boostInitialRate = 2
const fixedBoostProgress = 81

function boostCalculator(x: number) {
  if (x >= 0 && x <= fixedBoostProgress) {
    return 100
  }
  if (x > fixedBoostProgress && x <= 100) {
    return 100 - (100 / (100 - fixedBoostProgress)) * (x - fixedBoostProgress)
  }
  return 0
}

export const useMembershipStore = defineStore('credential', () => {
  const { publicKey } = useWallet()
  const connectionStore = useConnectionStore()
  const accessToken = useStorage('accessToken', '')

  const referralStore = useReferralStore()

  const { monitorTransaction, hasSufficientBalance } = useMonitorTransaction()
  const wallet = useAnchorWallet()

  const refreshToken = useStorage('refresh-token', '')

  const {
    getUserData,
    getPoolsInfo,
    userVerify,
    userRegister,
    getUserEvents,
    getUserRewards,
    getDailyRewards,
    getUserBalances,
    getUserReferrals,
    getHoldersClubConfig,
  } = useHoldersClubService()

  const state = reactive<CredentialState>({
    userData: undefined,
    rewards: undefined,
    dailyRewards: undefined,
    loading: false,
    loadingIssuer: false,
    events: undefined,
    balances: undefined,
    referrals: undefined,
  })

  const config = ref<IHoldersClubConfig>()
  const poolsInfo = ref<IHoldersClubPoolsInfo[]>()

  const isRegister = ref(false)
  const isRefresh = ref(false)
  const isLinked = ref(false)

  const isHoldersClubMember = computed(() => !!state.userData?.credentialMint || !!state.userData?.telegramUsername)

  const forcedRegistration = ref(false)

  async function loadUser(id?: string, force = false) {
    try {
      state.loading = true
      if (!publicKey.value && !force) {
        return
      }
      let data = await getUserData(id ?? publicKey.value!.toBase58(), connectionStore.cluster)
      if (!data && forcedRegistration.value) {
        data = await getUserData(id ?? publicKey.value!.toBase58(), connectionStore.cluster)
      }
      state.userData = data
    } finally {
      state.loading = false
      isRefresh.value = false
    }
  }

  async function loadUserData(id?: string, force = false) {
    try {
      if (!state.userData) {
        await loadUser(id, force)
      }
      state.loading = true
      const data = state.userData
      if (data?.id) {
        await Promise.all([
          loadRewards(data.id),
          loadDailyRewards(data.id),
          loadBalances(data.id),
          loadReferrals(data.id),
        ])
      }
    } catch (error) {
      console.log(error)
      state.userData = undefined
      state.rewards = undefined
      state.balances = undefined
      state.dailyRewards = undefined
      state.referrals = undefined
    } finally {
      state.loading = false
      await loadEvents(state.userData?.id)
    }
  }

  async function loadRewards(id: string) {
    try {
      state.rewards = await getUserRewards(id)
    } catch (error) {
      console.log(error)
    }
  }

  async function loadEvents(id?: string) {
    state.events = await getUserEvents(id)
  }

  async function loadBalances(id: string) {
    state.balances = await getUserBalances(id)
  }

  async function loadDailyRewards(id: string) {
    state.dailyRewards = await getDailyRewards(id)
  }

  async function loadReferrals(id: string) {
    state.referrals = await getUserReferrals(id)
  }

  async function deleteCredential(mintAddr: PublicKeyInitData) {
    const authority = publicKey.value!
    const mint = new PublicKey(mintAddr)

    const programId = TOKEN_2022_PROGRAM_ID

    const tokenAccount = getAssociatedTokenAddressSync(
      mint,
      authority,
      true,
      TOKEN_2022_PROGRAM_ID,
    )

    const instructions = [
      createBurnInstruction(tokenAccount, mint, authority, 1, [], programId),
      createCloseAccountInstruction(tokenAccount, authority, authority, [], programId),
      createCloseAccountInstruction(mint, authority, authority, [], programId),
    ]

    await monitorTransaction(
      sendTransaction(
        connectionStore.connection,
        wallet.value!,
        instructions, // await withPriorityFees({ instructions }),
        [],
      ),
      {
        onSuccess(signature) {
          console.log('signature', signature)
        },
      },
    )
  }

  async function createCredentialInstructions(ephemeralMint?: PublicKey): Promise<{
    instructions: TransactionInstruction[]
    signers: Signer[]
    mint: PublicKey
  }> {
    const programId = TOKEN_2022_PROGRAM_ID
    const authority = publicKey.value!
    const updateAuthority = new PublicKey(JPOOL_ISSUER)

    let mint = ephemeralMint
    const signers: Signer[] = []

    if (!mint) {
      const mintKeypair = Keypair.generate()
      mint = mintKeypair.publicKey
      signers.push(mintKeypair)
    }

    const metadata: TokenMetadata = {
      updateAuthority: authority,
      mint,
      name: HC_CREDENTIAL_NAME,
      symbol: HC_CREDENTIAL_SYMBOL,
      uri: `${API_HC_URL}/user/credential/${mint}/metadata`,
      // uri: config.value?.credentialMetadataUrl.replace('{mint}', mint.toBase58()),
      additionalMetadata: [],
    }

    const extraBytes = 0
    const metadataLen = pack(metadata).length + TYPE_SIZE + LENGTH_SIZE + extraBytes

    const mintLen = getMintLen([
      ExtensionType.MetadataPointer,
      ExtensionType.NonTransferable,
      ExtensionType.MintCloseAuthority,
    ])

    const mintLamports = await connectionStore.connection.getMinimumBalanceForRentExemption(mintLen + metadataLen)

    // derive the ata for the token owner and mint
    const associatedTokenAccount = getAssociatedTokenAddressSync(
      mint,
      authority,
      true,
      programId,
    )

    // build the transaction
    const instructions = [
      SystemProgram.createAccount({
        fromPubkey: publicKey.value!,
        newAccountPubkey: mint,
        space: mintLen,
        lamports: mintLamports,
        programId,
      }),
      createInitializeNonTransferableMintInstruction(mint, programId),
      createInitializeMetadataPointerInstruction(mint, authority, mint, programId),
      createInitializeMintCloseAuthorityInstruction(mint, authority, programId),
      createInitializeMintInstruction(mint, 0, authority, null, programId),
      createInitializeInstruction({
        programId,
        mint,
        metadata: mint,
        updateAuthority: authority,
        mintAuthority: authority,
        name: metadata.name,
        symbol: metadata.symbol,
        uri: metadata.uri,
      }),
      createAssociatedTokenAccountInstruction(
        authority,
        associatedTokenAccount,
        authority,
        mint,
        programId,
      ),
      createMintToInstruction(
        mint,
        associatedTokenAccount,
        authority,
        1,
        undefined,
        programId,
      ),
      createSetAuthorityInstruction(
        mint,
        authority,
        AuthorityType.MintTokens,
        null,
        undefined,
        programId,
      ),
      createUpdateAuthorityInstruction({
        metadata: mint,
        oldAuthority: authority,
        newAuthority: updateAuthority,
        programId,
      }),
    ]

    return { instructions, signers, mint }
  }

  async function registrationUser(mint?: PublicKey) {
    try {
      let message = ''
      state.loading = true
      let registerUserId: string
      if (state.userData && !isHoldersClubMember.value) {
        const { id } = await userVerify({
          userId: state.userData.id,
          credentialMint: mint!.toBase58(),
        })
        registerUserId = id
        message = 'User verified successfully'
      } else {
        const referralCode = referralStore.hcReferral ?? ''
        const { id } = await userRegister({
          credentialMint: mint?.toBase58(),
          wallet: publicKey.value!.toBase58(),
          cluster: connectionStore.cluster,
          referralCode,
        })
        registerUserId = id
        message = 'User registered successfully'
      }

      isRegister.value = true

      await loadUserData(registerUserId)

      Notify.create({
        type: 'positive',
        message,
      })
    } catch (error) {
      console.log(error)
    } finally {
      state.loading = false
    }
  }

  async function register(mintAddr?: PublicKey) {
    try {
      if (!publicKey.value) {
        clickWalletButton()
        return
      }

      if (mintAddr) {
        await registrationUser(mintAddr)
        return
      }

      const { instructions, signers, mint } = await createCredentialInstructions()

      state.loadingIssuer = true

      await hasSufficientBalance(instructions)

      await monitorTransaction(
        sendTransaction(
          connectionStore.connection,
          wallet.value!,
          await withPriorityFees({ instructions }),
          signers,
        ),
        {
          onSuccess: async (_signature: string) => {
            await registrationUser(mint)
          },
          onError: () => {
            Notify.create({
              type: 'negative',
              message: 'Failed to create credential. Please try again later',
            })
          },
        },
      )
    } catch (error) {
      console.log(String(error))
    } finally {
      state.loadingIssuer = false
    }
  }

  // deleteCredential('2FN9rWxustvatze77whGWniYztUhg46UY46tDVHnGLJ7')

  const preparedRewards = computed(() => {
    const data = state.rewards?.data
    if (!data) {
      return []
    }

    const rewards: { [key: string]: {
      createdAt: string
      base: number
      boosted: number
      referral: number
      activity: number
      jstaking: number
    } } = {}

    for (const reward of data) {
      const dateByUtc = dateToUtc(new Date(reward.createdAt))
      let key: 'base' | 'referral' | 'boosted' | 'activity' | 'jstaking'
      switch (reward.rewardProvider) {
        case 'base':
          key = 'base'
          break
        case 'jstaking':
          key = 'jstaking'
          break
        case 'referral':
          key = 'referral'
          break
        case 'raydium':
        case 'solayer':
        case 'saber':
        case 'meteora':
        case 'save-finance':
          key = 'boosted'
          break
        default:
          key = 'activity'
      }
      if (!rewards[String(dateByUtc)]) {
        rewards[String(dateByUtc)] = {
          createdAt: String(dateByUtc),
          base: key === 'base' ? Number(reward.amount) : 0,
          boosted: key === 'boosted' ? Number(reward.amount) : 0,
          referral: key === 'referral' ? Number(reward.amount) : 0,
          activity: key === 'activity' ? Number(reward.amount) : 0,
          jstaking: key === 'jstaking' ? Number(reward.amount) : 0,
        }
        continue
      }
      rewards[String(dateByUtc)]![key] += Number(reward.amount)
    }

    return Object.values(rewards).sort((a, b) => {
      const dateA = a.createdAt.split('T')[0]!
      const dateB = b.createdAt.split('T')[0]!

      return dateA.localeCompare(dateB)
    })
  })

  const userDepositedTokens = ref()

  watch([
    () => poolsInfo.value,
    () => state.balances,
  ], ([pools, balances]) => {
    if (!pools || !balances) {
      return
    }
    const balancesWithAmount = balances.filter(bal => Number(bal.amount) > 0)
    userDepositedTokens.value = balancesWithAmount.map((bal) => {
      const pool = pools.find(p => p.lpMint === bal.mint)
      if (pool) {
        const userLPTokens = Number(bal.amount) / 10 ** bal.decimals
        const userShare = userLPTokens / (pool.lpSupply / 10 ** pool.lpDecimal)

        return {
          lpMint: pool.lpMint,
          rewardProvider: bal.rewardProvider,
          assets: pool.tokenAmounts?.map((amount, index) => {
            const asset = TOKEN_LIST.find(t => t.mint === pool.mints[index])
            return {
              mint: pool.mints[index],
              amount: userShare * Number(amount),
              symbol: asset?.symbol,
              icon: asset?.icon,
            }
          }),
        }
      }
      if ((bal.rewardProvider === 'save-finance' || bal.rewardProvider === 'solayer') && Number(bal.amount) > 0) {
        return {
          lpMint: undefined,
          rewardProvider: bal.rewardProvider,
          assets: [
            {
              mint: '',
              amount: Number(bal.amount) / LAMPORTS_PER_SOL,
              symbol: 'JSOL',
              icon: '',
            },
          ],
        }
      }
      return null
    }).filter(Boolean)
  }, { immediate: true })

  watch(() => publicKey.value, async (p) => {
    if (p) {
      if (!isLinked.value && !refreshToken.value) {
        await loadUserData()
      }
      return
    }
    if (!p && accessToken.value) {
      return
    }
    state.userData = undefined
    state.rewards = undefined
    state.dailyRewards = undefined
    state.balances = undefined
    state.referrals = undefined
    await loadEvents()
  }, { immediate: !import.meta.env.SSR })

  void (async function () {
    try {
      const [hcConfig, meteoraPools] = await Promise.all([
        getHoldersClubConfig(),
        getPoolsInfo('meteora'),
        // getPoolsInfo('raydium'),
        // getPoolsInfo('saber'),
      ])
      const pools = [...meteoraPools]
      config.value = hcConfig
      poolsInfo.value = pools
    } catch (error) {
      console.error(error)
    }
  })()

  const boostStartDate = ref(boostStartDateDefault)
  const boostEndDate = ref(boostEndDateDefault)

  function boostProgress(date: number) {
    const startDate = boostStartDate.value
    const endDate = boostEndDate.value
    if (startDate === 0 || endDate === 0) {
      return -1
    }
    if (date <= startDate) {
      return -1
    }
    if (date >= endDate) {
      return 100
    }
    // Calculate progress as a percentage
    return ((date - startDate) / (endDate - startDate)) * 100
  }

  // TODO: calculation depends on Backend data
  function dateBoostRate(date: number) {
    const progress = 100 - Math.max(0, Math.min(100, boostCalculator(boostProgress(date))))
    const initialRate = boostInitialRate
    return initialRate - (initialRate - 1) * (progress / 100)
  }

  const currentBoostRate = computed(() => dateBoostRate(Date.now()).toFixed(2))

  async function authWallet(): Promise<{ publicKey: string, signature: string } | undefined> {
    const timestamp = Date.now().toString()
    if (!wallet.value?.signMessage) {
      return
    }
    const signature = await wallet.value.signMessage(new TextEncoder().encode(timestamp))
    return {
      publicKey: wallet.value.publicKey.toBase58(),
      signature: `${bs58.encode(signature)}.${timestamp}`,
    }
  }

  return {
    state,
    config,
    poolsInfo,
    isRegister,
    isRefresh,
    isLinked,

    isHoldersClubMember,
    forcedRegistration,

    register,
    registrationUser,
    createCredentialInstructions,

    loadUser,
    loadUserData,
    loadBalances,
    deleteCredential,

    preparedRewards,

    boostStartDate,
    boostEndDate,
    dateBoostRate,
    currentBoostRate,

    authWallet,
  }
})
