import { StorageSerializers } from '@vueuse/core'
import { defineStore } from 'pinia'
import { REF_LIFETIME } from '~/config'

const REF_PREFIX_ALL = 'a:'
const REF_HC_STORAGE_KEY = 'referral-hc'
const REFERRER_STORAGE_KEY = 'referrer'

export const useReferralStore = defineStore('referral', () => {
  const router = useRouter()
  const route = useRoute()

  const referrerStorage = useLocalStorage<Referrer>(REFERRER_STORAGE_KEY, null, {
    serializer: StorageSerializers.object,
    listenToStorageChanges: false,
    writeDefaults: false,
  })

  const hcReferralStorage = useLocalStorage<string>(REF_HC_STORAGE_KEY, '')

  const referrer = computed({
    get() {
      const data = referrerStorage.value
      if (data && Date.now() < Number(data.expiration)) {
        return data.code
      }
      return referrerStorage.value = null
    },
    set(code) {
      if (!code) {
        return referrerStorage.value = null
      }
      referrerStorage.value = { code, expiration: Date.now() + REF_LIFETIME }
    },
  })

  const hcReferral = computed({
    get() {
      return hcReferralStorage.value
    },
    set(code) {
      if (code) {
        hcReferralStorage.value = String(code)
      }
    },
  })

  watch(() => route.query, (q) => {
    if (!q) {
      return
    }
    const query = { ...q }
    if (query.referrer && !referrer.value) {
      referrer.value = String(query.referrer)
      delete query.referrer
    }

    if (query.ref) {
      if (String(query.ref).startsWith(REF_PREFIX_ALL)) {
        query.ref = String(query.ref).slice(REF_PREFIX_ALL.length)
        referrer.value = String(query.ref)
      }
      hcReferral.value = String(query.ref)
      delete query.ref
    }
    router.replace({ query }).then()
  }, { immediate: true })

  return {
    referrer,
    hcReferral,
  }
})

export type Referrer = {
  code: string
  expiration: number
}
